"use strict";

//touchstartに対応してたらtouchstart、してなければclick
const myTap = window.ontouchstart===null?"touchstart":"click";

//
// DOM読み込み完了
//
document.addEventListener('DOMContentLoaded', function(e) {
  console.log('DOM Content Loaded.');

  //  PC＋SPメニュー用
  var btnPagetop = document.querySelectorAll('.btn-pagetop')[0];

  // ページトップに戻るボタン
  btnPagetop.addEventListener(myTap, function() {
    var scroll = new SmoothScroll();
    scroll.animateScroll( 0 );
  }, false);
  
  // スクロールイベントを設定
  let setTimeoutId = null ;
  window.addEventListener( "scroll", function() {
    // スクロール終了時の処理内容
    if( window.pageYOffset > 120 ) {
//        console.log('fixed_up_add:' + window.pageYOffset);
      btnPagetop.classList.add('visible');
    } else {
//        console.log('fixed_up_del:' + window.pageYOffset);
      btnPagetop.classList.remove('visible');
    }
  });
  
}, false);

//
// 完全に読み込み完了
//
window.addEventListener('load', function() {
  console.log('Page Loaded .');
  // smoothScroll
  var scroll = new SmoothScroll('a[href*="#"]', {
    // Selectors
    ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
    header: null, // Selector for fixed headers (must be a valid CSS selector)
    // Speed & Easing
    speed: 200, // Integer. How fast to complete the scroll in milliseconds
    offset: 0, // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
    easing: 'easeInOutCubic', // Easing pattern to use
    customEasing: function (time) {}, // Function. Custom easing pattern
    // Callback API
    before: function () {}, // Callback to run before scroll
    after: function () {} // Callback to run after scroll
  });
  
  
  // ライトボックス
  var galleryOpts = {
    // Whether pressing the arrow keys should move to the next/previous slide.
    arrowNavigation: true,
  };
  var luminousOpts = {
    // These options have the same defaults and potential values as the Luminous class.
    namespace: null,
    sourceAttribute: 'href',
    captionAttribute: null,
    openTrigger: 'click',
    closeTrigger: 'click',
    closeWithEscape: true,
    closeOnScroll: false,
    appendToSelector: 'body',
    onOpen: null,
    onClose: null,
    includeImgixJSClass: false,
    injectBaseStyles: true,
  };
  new LuminousGallery (document.querySelectorAll('[href$=".jpg"]'), galleryOpts, luminousOpts);
});
